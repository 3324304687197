import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { UserRoutingModule } from './user-routing.module';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserPhotoComponent } from './user-photo/user-photo.component';

import { UserService } from './user.service';

@NgModule({
    declarations: [UserHeaderComponent, UserDetailComponent, UserPhotoComponent],
    imports: [SharedModule, UserRoutingModule],
})
export class UserModule {
    static forRoot() {
        return {
            ngModule: UserModule,
            providers: [UserService],
        };
    }
}
