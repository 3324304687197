import { Component, OnInit, Input } from '@angular/core';
import { PhysicianService } from '../physicians/physicians.service';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-banner',
    styleUrls: ['./banner.less'],
    templateUrl: './banner.component.html',
})
export class BannerComponent implements OnInit {
    currentUserId: number;
    missingDocuments: string[];
    showBanner: boolean = false;
    bannerText: string = '';
    havePortalAccess: boolean = false;

    constructor(private physicianService: PhysicianService, private authService: AuthService, private claimService: ClaimsService) {}

    ngOnInit(): void {
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.havePortalAccess = this.claimService.hasClaim(ClaimTypes.PhysicianExpertPortalServiceManagement, [ClaimValues.FullAccess]);

        if (this.currentUserId > 0 && this.havePortalAccess) {
            this.getPhysicianExpertMissingDocuments();
        }
    }

    getPhysicianExpertMissingDocuments(): void {
        this.physicianService.getMissingDocuments(this.currentUserId).subscribe((answer) => {
            this.missingDocuments = answer;

            if (this.missingDocuments.length > 0) {
                const missingDocs = this.missingDocuments.join(', ');
                this.bannerText = `Please upload your updated ${missingDocs} for Imedview to review and approve.`;
                this.showBanner = true;
            }
        });
    }
}
