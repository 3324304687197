import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { ITask } from '@model/interfaces/task';
import { Observable } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyTask: ITask = {
    Archived: false,
    AssignedToTypeId: 1,
    CreatedById: 0,
    DateCreated: new Date(),
    DueDate: null,
    Id: 0,
    ImedClaimId: 0,
    IsAutomated: true,
    IsPhysicalCheckRequiredToFacility: false,
    Notes: null,
    TaskDescriptions: null,
    TaskStatusId: 1,
    TriggeredFromServicePhysicalCheckRequiredToFacility: false,
    TriggeredFromServiceProcessServerFee: false,
    TriggeredFromServiceWitnessFee: false,
    TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired: false,
    TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired: false,
};

@Injectable({
    providedIn: 'root',
})
export class TaskService extends BaseService<ITask> {
    constructor(public http: HttpClient) {
        super('/physician-expert/tasks', http);
    }

    getEmptyTask(): ITask {
        return { ...emptyTask };
    }

    createTask(task: ITask): Observable<number> {
        return this.http.post<number>(`/physician-expert/tasks/create-task`, task);
    }

    getTasksForService(serviceId: number, searchParams: SearchParams): Observable<any> {
        return this.http.get<any>(`/physician-expert/tasks/${serviceId}/search-task-by-service`, {
            observe: 'response',
            params: this.getHttpParams(searchParams),
        });
    }

    updateTask(data: ITask): Observable<number> {
        return this.http.post<number>(`/physician-expert/tasks/update-task/${data.Id}`, data);
    }

    getUserAssignmentTypes(): Observable<any> {
        return this.http.get<any>(`/physician-expert/tasks/get-user-assignment-types`);
    }
}
