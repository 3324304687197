// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-banner {
  background-color: #4bbb65;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/physician-expert-portal/banner/banner.less"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,cAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;AACJ","sourcesContent":[".custom-banner {\n    background-color: rgb(75, 187, 101);\n    color: #ffffff;\n    font-weight: bold;\n    padding: 10px;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
