import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { Router, ActivatedRoute } from '@angular/router';
import { ITask } from '@model/interfaces/task';

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
})
export class TaskDetailComponent {
    @Input() tasks: ITaskDTO[];
    @Input() canEdit: boolean;
    @Input() currentUserId: number;
    @Output() emitAdd: EventEmitter<any> = new EventEmitter<any>();

    userOrRolesList: string;
    isEditing = false;

    constructor(private router: Router, private route: ActivatedRoute) {}

    seeAll(): void {
        void this.router.navigate(['tasks'], { relativeTo: this.route });
    }

    addTask(): void {
        this.emitAdd.emit();
    }

    showRolesOrUsers(task: ITaskDTO): string {
        return task.TaskUsersList && task.TaskUsersList.trim().length ? task.TaskUsersList : task.TaskUserRolesList;
    }

    editTask(task: ITask): void {
        if (this.allowEdit(task)) {
            void this.router.navigate([`tasks/${task.Id}`], { relativeTo: this.route });
        }
    }

    allowEdit(task: ITask): boolean {
        return task.CreatedById === this.currentUserId && !task.IsAutomated;
    }
}
