import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';
import { Observable, BehaviorSubject } from 'rxjs';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { ICreateDocumentDTO } from '../../model/interfaces/custom/create-document.dto';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';

export const emptyCreateDocumentDTO: ICreateDocumentDTO = {
    AttachmentTypeId: 0,
    FileItem: null,
};

export enum DocumentCardSortPropertyEnums {
    AttachmentType = 1,
    Filename,
    Service,
    UploadedBy,
    DateUploaded,
    Source,
    Pages,
}

export enum DocumentCardSortDirectionEnums {
    Asc,
    Desc,
}

export interface IDocumentSortParams {
    property: DocumentCardSortPropertyEnums;
    direction: DocumentCardSortDirectionEnums;
}
@Injectable()
export class BaseAttachmentsService extends BaseService<IAttachmentDTO> {
    private _sortPropertyMap = new Map<DocumentCardSortPropertyEnums, string>([
        [DocumentCardSortPropertyEnums.AttachmentType, 'AttachmentType.Name'],
        [DocumentCardSortPropertyEnums.Filename, 'Name'],
        [DocumentCardSortPropertyEnums.UploadedBy, 'UploadedBy.FirstName'],
        [DocumentCardSortPropertyEnums.DateUploaded, 'DateUploaded'],
    ]);

    private _sortOrderMap = new Map<DocumentCardSortDirectionEnums, string>([
        [DocumentCardSortDirectionEnums.Asc, 'Asc'],
        [DocumentCardSortDirectionEnums.Desc, 'Desc'],
    ]);

    private _defaultSort: IDocumentSortParams = {
        direction: DocumentCardSortDirectionEnums.Asc,
        property: DocumentCardSortPropertyEnums.AttachmentType,
    };
    public sortProperty: BehaviorSubject<IDocumentSortParams> = new BehaviorSubject<IDocumentSortParams>(this._defaultSort);

    constructor(public http: HttpClient) {
        super('/', http);
    }

    resetSortProperty(): void {
        this.sortProperty.next(this._defaultSort);
    }

    currentSort(): Observable<IDocumentSortParams> {
        return this.sortProperty.asObservable();
    }

    getEmptyCreateDocumentDTO(): ICreateDocumentDTO {
        return { ...emptyCreateDocumentDTO };
    }

    createAndUpload(type: string, entityId: number, file: File, attachmentTypeId: number, templateId: number, noofPages: number): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(`/physician-expert/documents/${type}/${entityId}/${attachmentTypeId}/${noofPages}/${templateId}`, formData);
    }

    downloadUploadedDocuments(type: string, serviceId: number, documentId: number): Observable<Blob> {
        return this.http.get('/physician-expert/documents/' + type + '/' + serviceId + '/' + documentId, { responseType: 'blob' as const });
    }

    downloadEmailedDocument(type: string, documentId: number): Observable<Blob> {
        return this.http.get('/physician-expert/email-document/' + type + '/' + documentId, { responseType: 'blob' as const });
    }

    all(type: string, entityId: number, name: string): Observable<HttpResponse<IAttachmentDTO[]>> {
        const extraParams: ExtraSearchParams[] = [];
        extraParams.push(
            new ExtraSearchParams({
                name: 'EntityId',
                value: entityId.toString(),
            }),
        );

        const searchparams: IEntitySearchParams = {
            extraParams: extraParams,
            order: this._sortPropertyMap.get(this.sortProperty.getValue().property),
            orderDirection: this._sortOrderMap.get(this.sortProperty.getValue().direction),
            query: name,
        };
        const searchEntity = new SearchParams(searchparams);
        const params = this.getHttpParams(searchEntity);

        return this.http.get<IAttachmentDTO[]>('/physician-expert/documents/' + type + '/all', { observe: 'response', params: params });
    }

    uploadToIndividualDocumentCard(entityId: number, file: File, attachmentTypeId: number, templateId: number, noofPages: number): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(
            `/physician-expert/physician-document-upload/${entityId}/${attachmentTypeId}/${noofPages}/${templateId}`,
            formData,
        );
    }
}
