import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RequestAccessService {
    constructor(private http: HttpClient) {}

    requestAccess(requestorEmail: string): any {
        const isClientPortalAccessRequest = false;
        return this.http.post(`/users/send-request-email/${isClientPortalAccessRequest}`, { email: requestorEmail });
    }
}
