import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { TasksEntityListConfig } from './task-list.entity-list-config';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { TaskService } from '../task.service';
import { AuthService } from '@mt-ng2/auth-module';
import { ImedClaimService } from '../../imed-claims/imed-claim-basic-info/imed-claim.service';
import { ITask } from '@model/interfaces/task';

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.component.html',
})
export class TaskListComponent implements OnInit, OnDestroy {
    tasks: ITaskDTO[];
    id: number;
    currentPage = 1;
    itemsPerPage = 10;
    query = '';
    total: number;
    currentUserId: number;
    entityListConfig: TasksEntityListConfig;
    order: string;
    orderDirection: string;

    isDuplicate: boolean;
    isDuplicateSubscription: Subscription;

    canAddTask = false;

    constructor(
        private taskService: TaskService,
        private route: ActivatedRoute,
        private imedClaimService: ImedClaimService,
        private router: Router,
        private authService: AuthService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.entityListConfig = new TasksEntityListConfig(this.currentUserId);
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
        this.order = this.entityListConfig.getDefaultSortProperty();

        this.id = +this.route.parent.parent.snapshot.paramMap.get('serviceId');
        this.isDuplicateSubscription = this.imedClaimService.getIsDuplicate().subscribe((val) => {
            this.isDuplicate = val;
            this.canAddTask = !val;
        });
        this.getTasks();
    }

    ngOnDestroy(): void {
        if (this.isDuplicateSubscription) {
            this.isDuplicateSubscription.unsubscribe();
        }
    }

    getTasksCall(): Observable<HttpResponse<ITaskDTO[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.taskService.getTasksForService(this.id, searchparams);
    }

    getTasks(): void {
        this.getTasksCall().subscribe((answer) => {
            this.tasks = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.cdr.detectChanges();
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getTasks();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTasks();
    }

    addTask(): void {
        void this.router.navigate(['add'], { relativeTo: this.route });
    }

    taskSelected(event: any): void {
        if (this.allowEdit(event.entity as ITask)) {
            void this.router.navigate([`${event.entity.Id}`], { relativeTo: this.route });
        }
    }

    allowEdit(task: ITask): boolean {
        return task.CreatedById === this.currentUserId && !task.IsAutomated;
    }
}
