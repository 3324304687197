import { DocumentDynamicControlsPartial } from '../../../model/partials/document-partial.form-controls';
import { Component, Input, OnInit, EventEmitter, Output, ViewChildren, QueryList, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import { saveAs } from 'file-saver';
import { UntypedFormBuilder, AbstractControl, UntypedFormControl } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AttachmentControlComponent } from '../../../common/attachment-control/attachment-control.component';
import {
    BaseAttachmentsService,
    DocumentCardSortDirectionEnums,
    DocumentCardSortPropertyEnums,
    IDocumentSortParams,
} from '../../../common/attachments/base-attachments.service';
import { AttachmentTypeService } from '../../../common/attachments/attachment-type.service';
import { ICreateDocumentDTO } from '../../../model/interfaces/custom/create-document.dto';
import { ImedClaimServiceService } from '../imed-claim-service.service';
import { DocumentTransactionLogService } from './document-transaction-log.service';
import { DocumentTransactionTypeEnums } from '../../../common/constants/document-transaction-type.enums';
import { debounceTime } from 'rxjs/operators';
import { AttachmentTypeEnums } from '../../../../app/common/constants/Enums';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-imed-claim-service-attachments',
    styles: ['.success { color: #8AC175; }'],
    templateUrl: './imed-claim-attachments.component.html',
})
export class ImedClaimServiceAttachmentsComponent implements OnInit, OnDestroy {
    @Input('canEdit') canEdit: boolean;
    @Input('entityId') entityId: number;
    @Input('type') type: string;
    @Input() showUploadButtonGroup = true;
    @Output('onDocumentChanged') onDocumentChanged: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('docComponent') docComponent: AttachmentControlComponent;
    @Input('documentArray') documentArray: IAttachmentDTO[];
    errorMessage: string;
    @ViewChildren(AttachmentControlComponent) private _attachmentComponents: QueryList<AttachmentControlComponent>;

    attachmentTypes: IAttachmentType[] = [];
    abstractAttachmentTypeComponent: AbstractControl;
    abstractDocumentControls: any;
    formCreated = false;
    isEditing = false;
    docsToDownload: IAttachmentDTO[];
    doubleClickIsDisabled = false;
    PhysicianExpertImedClaimServiceEnum = 16;
    Files: ICreateDocumentDTO[] = [];
    documentsSavedSubscription: Subscription = null;
    attachmentComponentSubscription: Subscription;

    saveDocsOnCreateSubscription: Subscription;
    subscriptions: Subscription = new Subscription();

    showReuploadForm = false;

    sortPropertyEnums = DocumentCardSortPropertyEnums;
    sortOrderEnums = DocumentCardSortDirectionEnums;
    currentSort: IDocumentSortParams;
    searchControl = new UntypedFormControl();
    isPasswordProtected: boolean = false;

    @Input() isExpandable = false;
    @Input() isModal = false;
    @Output() onModalClose = new EventEmitter<any>();
    expanded = false;
    documentsModal: IModalWrapperApi;
    modalOptions: IModalOptions = {
        allowOutsideClick: true,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        width: '95vw',
    };

    constructor(
        private attachmentsService: BaseAttachmentsService,
        private attachmentTypesService: AttachmentTypeService,
        private fb: UntypedFormBuilder,
        private notificationsService: NotificationsService,
        private imedClaimServiceService: ImedClaimServiceService,
        private docTransactionLogService: DocumentTransactionLogService,
        private cdr: ChangeDetectorRef,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        if (this.imedClaimServiceService.unsuccessfulUploads && this.imedClaimServiceService.unsuccessfulUploads.length) {
            this.Files = [...this.imedClaimServiceService.unsuccessfulUploads];
            this.showReuploadForm = true;
        }
        this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => this.onDocumentChanged.emit(value));

        this.imedClaimServiceService.unsuccessfulUploads = null;
        this.isEditing = this.Files.some((doc) => doc.FileItem.isError) ? true : false;
        this.getAttachmentTypesAndBuildForm();
        this.subscriptions.add(
            this.attachmentsService.currentSort().subscribe((sort) => {
                this.currentSort = sort;
            }),
        );
        this.subscriptions.add(this.imedClaimServiceService.clearDocumentUploadQueue.subscribe(() => this.clearDocumentUploadQueue()));
        this.cdr.detectChanges();
    }

    afterFormCreate(): void {
        if (!this.documentsSavedSubscription) {
            this.documentsSavedSubscription = this.imedClaimServiceService.documentsSaved$.subscribe((result) => {
                this.onDocumentSuccessfulSave(result);
            });
        }
        this.saveDocsOnCreateSubscription = this.imedClaimServiceService.saveDocsOnCreate.subscribe((id) => {
            this.entityId = id;
            this.saveOnCreate();
        });

        this.attachmentComponentSubscription = this._attachmentComponents.changes.subscribe((components: QueryList<AttachmentControlComponent>) => {
            this.docComponent = components.first;

            if (this.docComponent) {
                this.docComponent.uploader.onAfterAddingFile = () => {
                    const latestFile = this.docComponent.uploader.queue[this.docComponent.uploader.queue.length - 1];
                    if (latestFile) {
                        const documentDTO = this.attachmentsService.getEmptyCreateDocumentDTO();
                        documentDTO.FileItem = latestFile;
                        this.docComponent.uploader.queue = [];
                        this.Files.push(documentDTO);

                        this.imedClaimServiceService.notifyDocumentsUpdate(this.Files);
                    }
                };
            }
        });
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.documentsSavedSubscription.unsubscribe();
        if (this.attachmentComponentSubscription) {
            this.attachmentComponentSubscription.unsubscribe();
        }
        this.saveDocsOnCreateSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    removeItem(evt: any, indexToRemove: number): void {
        this.Files = this.Files.filter((_, index) => indexToRemove !== index);
    }

    getAttachmentTypesAndBuildForm(): void {
        this.attachmentTypesService.getAttachmentTypes(this.PhysicianExpertImedClaimServiceEnum).subscribe((types) => {
            this.attachmentTypes = types;
            this.buildForm();
        });
    }

    getAttachmentTypeName(id: number): string {
        return this.attachmentTypes.find((type) => type.Id === id).Name;
    }

    buildForm(): void {
        this.abstractDocumentControls = new DocumentDynamicControlsPartial(null, {
            attachmentTypes: this.attachmentTypes,
        }).Form;
        this.formCreated = true;
        this.afterFormCreate();
    }

    get showUploadArea(): boolean {
        return this.isEditing && this.canEdit;
    }

    ClearSearch(): void {
        this.searchControl.setValue('');
    }

    setAttachmentType(attachmentTypeId: number, index: number): void {
        if (this.Files[index]) {
            this.Files[index].AttachmentTypeId = attachmentTypeId;
            this.imedClaimServiceService.notifyDocumentsUpdate(this.Files);
        }
    }

    save(): void {
        if (this.Files && this.Files.length) {
            for (const doc of this.Files) {
                if (
                    doc.AttachmentTypeId === +AttachmentTypeEnums.Bill_Physician_Expert &&
                    !doc.FileItem.file.type.includes('pdf') &&
                    !doc.FileItem.file.type.includes('PDF')
                ) {
                    this.showPasswordProtectedPopUp();
                    return;
                }
            }
        }

        if ((this.showReuploadForm || this.abstractAttachmentTypeComponent.valid) && this.Files && this.Files.length) {
            void this.tryUploadDocuments();
        } else {
            this.notificationsService.error('Please ensure you have uploaded files and correctly assigned them attachment types.');
            this.buildForm();
        }
    }

    saveOnCreate(): void {
        if (this.Files && this.Files.length) {
            this.imedClaimServiceService.unsuccessfulUploads = [];
            void this.tryUploadDocuments();
        }
    }

    // Async upload function that sets file upload status upon successful or unsuccessful upload
    async upload(file: ICreateDocumentDTO): Promise<any> {
        file.FileItem.isUploading = true;
        file.FileItem.isError = false;
        await this.attachmentsService.createAndUpload(this.type, this.entityId, file.FileItem._file, file.AttachmentTypeId, 0, 0).toPromise();
    }

    // Iterate over file array and upload each file sequentially, log error in db
    async tryUploadDocuments(): Promise<void> {
        for (const file of this.Files) {
            if (!file.FileItem.isUploaded) {
                try {
                    await this.upload(file).then(() => this.setDocumentUploadStatus(file.FileItem, true));
                } catch (err) {
                    if (err.error === 'Password Protected.') {
                        this.isPasswordProtected = true;
                    }
                    this.setDocumentUploadStatus(file.FileItem, false);
                    this.docTransactionLogService.logError(err as HttpErrorResponse, null, DocumentTransactionTypeEnums.MANUAL_UPLOAD_FE).subscribe();
                }
            }
        }
        if (this.showUploadButtonGroup) {
            this.completeDocumentsSave();
        } else {
            this.imedClaimServiceService.unsuccessfulUploads = this.Files.filter((doc) => doc.FileItem.isError);
            this.imedClaimServiceService.showConfirmation.emit();
        }
    }

    // Check if any of the queued documents are in error status, if so show error and allow for reupload
    completeDocumentsSave(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false), 200);
        if (this.queueHasErrors) {
            if (this.isPasswordProtected) {
                this.notificationsService.error(
                    `One or more documents have been uploaded with protection. Please re-upload the document without protection.`,
                );
                this.isPasswordProtected = false;
            } else {
                this.notificationsService.error(`The following files marked with a red 'X' failed to upload, click 'Try Again' to retry the upload.`);
            }
        } else {
            this.onDocumentChanged.emit();
            this.clearDocumentUploadQueue();
            this.isEditing = false;
        }
    }

    showPasswordProtectedPopUp(): void {
        this.modalService
            .showModal({
                titleText: `Please convert your bill to a .pdf document to upload`,
                showCancelButton: false,
                confirmButtonText: 'OK',
            })
            .subscribe((res) => {
                if (res.isConfirmed) {
                    return;
                }
            });
    }

    // Set queued document's upload status
    setDocumentUploadStatus(doc: FileItem, uploaded: boolean): void {
        doc.isUploading = false;
        doc.isUploaded = uploaded;
        doc.isError = !uploaded;
    }

    get queueHasErrors(): boolean {
        return this.Files.some((file) => file.FileItem.isError);
    }

    onDocumentSuccessfulSave(savedDocs: IAttachmentDTO[]): void {
        this.documentArray.concat(savedDocs);
        this.onDocumentChanged.emit();
        this.clearDocumentUploadQueue();
        this.ngOnInit();
    }

    clearDocumentUploadQueue(): void {
        this.docComponent.uploader.queue = [];
        this.Files = [];
    }

    cancel(): void {
        this.clearDocumentUploadQueue();
        this.onDocumentChanged.emit();
        this.showReuploadForm = false;
        this.isEditing = false;
    }

    downloadDoc(index: string | number): void {
        if (this.documentArray[index].IsManual) {
            this.attachmentsService.downloadUploadedDocuments(this.type, this.entityId, this.documentArray[index].Id as number).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, this.documentArray[index].Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err as HttpErrorResponse, this.documentArray[index].Id as number, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        } else {
            this.attachmentsService.downloadEmailedDocument(this.type, this.documentArray[index].Id as number).subscribe(
                (x) => {
                    const thefile = new Blob([x], { type: 'application/octet-stream' });
                    saveAs(thefile, this.documentArray[index].Name);
                },
                (err) => {
                    this.docTransactionLogService
                        .logError(err as HttpErrorResponse, this.documentArray[index].Id as number, DocumentTransactionTypeEnums.MANUAL_DOWNLOAD_FE)
                        .subscribe();
                    this.notificationsService.error('Document download failed');
                },
            );
        }
    }

    openPDFInNewTab(document: IAttachmentDTO): void {
        window.open(environment.docPath + document.FilePath, '_blank');
    }

    sort(sortProperty: DocumentCardSortPropertyEnums): void {
        const direction = this.currentSort.property === sortProperty ? 1 - this.currentSort.direction : DocumentCardSortDirectionEnums.Asc;
        this.attachmentsService.sortProperty.next({ property: sortProperty, direction: direction });
    }

    getOpacityForArrow(selectedProperty: DocumentCardSortPropertyEnums, selectedDirection: DocumentCardSortDirectionEnums): number {
        if (!selectedProperty) {
            return 0.3;
        }
        return this.currentSort.direction === selectedDirection && this.currentSort.property === selectedProperty ? 1 : 0.3;
    }

    getAttachmentTypeDisplayName(document: IAttachmentDTO): string {
        if (document.AttachmentTypeId === +AttachmentTypeEnums.Automated_Templates && document.AutomatedTemplate) {
            return document.AutomatedTemplate.Name;
        } else if (document.AttachmentType) {
            return document.AttachmentType.Name;
        } else {
            return '';
        }
    }

    toggleCardSize(): void {
        if (this.isModal) {
            this.onModalClose.emit();
        } else {
            if (this.expanded) {
                this.expanded = false;
                this.documentsModal.close();
            } else {
                this.expanded = true;
                this.documentsModal.show();
            }
        }
    }
}
