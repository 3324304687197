import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@mt-ng2/auth-module';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { appPaths } from '../default-routes/app-paths.library';

const physicianRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: UploadDocumentsComponent,
        path: appPaths.physicinExpertDocumentUpload,
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(physicianRoutes)],
})
export class PhysicianRoutingModule {}
