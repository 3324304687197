import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import * as moment from 'moment-timezone';

export class ImedClaimServicesEntityListConfig extends EntityListConfig {
    constructor() {
        super({
            columns: [
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        return `${s.ClaimantName} `;
                    },
                    fireOnColumnSelected: true,
                    name: 'Claimant Name',
                    sort: {
                        sortProperty: 'ClaimantName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['ServiceType'],
                    fireOnColumnSelected: true,
                    name: 'Service Type',
                    sort: {
                        sortProperty: 'ServiceType',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        if (s.AppointmentDateTime) {
                            return moment.tz(s.AppointmentDateTime, s.Timezone).format('LLL');
                        }
                        return '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Appointment Date',
                    sort: {
                        sortProperty: 'AppointmentDateTime',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        return s.ServiceStatus;
                    },
                    fireOnColumnSelected: true,
                    name: 'Service Status',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        if (s.ReportDueBy) {
                            return `${moment.utc(s.ReportDueBy).format('MM/DD/YYYY')}`;
                        }
                        return '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Report Due Date',
                    sort: {
                        sortProperty: 'ReportDueBy',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => {
                        if (!(entity && entity.RecordsSentDate)) {
                            return '';
                        }
                        const recordsSentDate = new Date(entity.RecordsSentDate);
                        return `${moment.utc(recordsSentDate).format('MM/DD/YYYY')}`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Records Sent',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => {
                        if (!(entity && entity.AddlRecordsSentDate)) {
                            return '';
                        }
                        const addlRecordsSentDate = new Date(entity.AddlRecordsSentDate);
                        return `${moment.utc(addlRecordsSentDate).format('MM/DD/YYYY')}`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Addl Records Sent',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) =>
                        entity.HasRadiologyReviewAsChildService || entity.IsChildRadiologyReviewService ? 'Yes' : 'No',
                    name: 'Radiology Review',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            rowClass: (entity: IViewGetServicesForList) => {
                let classesToAdd = '';
                if (entity.HighValueService) {
                    classesToAdd += 'high-value-service';
                }
                if (
                    entity.ServiceStatus === 'Complete, to be invoiced' ||
                    entity.ServiceStatus === 'Complete' ||
                    entity.ServiceStatus === 'Complete, to be invoiced, report not requested'
                ) {
                    classesToAdd += 'archived';
                }
                return classesToAdd;
            },
        } as IEntityListConfig);
    }
}
