import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ITask } from '@model/interfaces/task';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '@model/interfaces/user';
import { TaskDynamicConfig } from '../task.dynamic-config';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ITaskAssignedUser } from '@model/interfaces/task-assigned-user';
import { emptyTask, TaskService } from '../task.service';
import { MetaItem } from '@mt-ng2/base-service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { AssignedToTypes } from '../../common/constants/assigned-to-type.enum';
import { TaskDynamicControlsPartial } from '../../model/partials/task-partial.form-controls';
import { AuthService } from '@mt-ng2/auth-module';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { ImedClaimService } from '../../imed-claims/imed-claim-basic-info/imed-claim.service';
import { ITaskUserAssignmentTypeAssociation } from '@model/interfaces/task-user-assignment-type-association';

@Component({
    selector: 'app-task-add',
    templateUrl: './task-basic-info.component.html',
})
export class TaskBasicInfoComponent implements OnInit {
    task: ITask;
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    imedClaimId: number;
    users: IUser[];
    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    taskForm = new UntypedFormGroup({ Task: new UntypedFormGroup({}) });
    formFactory: TaskDynamicConfig<ITask>;
    doubleClickIsDisabled = false;
    controls: any;
    userAssignmentTypsSelectItems: MultiselectItem[];
    selectedUserAssignmentTypes: MetaItem[] = [];
    assignedUsers: ITaskAssignedUser[];
    formValid = true;
    userAssignmentTypeSubscription: Subscription;
    pageTitle: string;
    fromTaskList = false;
    userAssignmentTypes: MetaItem[];
    imedClaim: IImedClaim;
    imedClaimServiceId: number;
    claimantName: string;

    @Output() emitSave: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() emitCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input('fromImedClaimService') fromImedClaimService: boolean;

    constructor(
        private taskService: TaskService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationsService: NotificationsService,
        private imedClaimsService: ImedClaimService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.id = +this.route.snapshot.paramMap.get('taskId');
        this.imedClaimServiceId = +this.route.snapshot.paramMap.get('serviceId');

        if (this.id > 0) {
            this.pageTitle = 'Edit Task';
            this.taskService.getById(this.id).subscribe((response: ITask) => {
                this.task = response;
                this.getImedClaimByServiceId();
            });
        } else {
            this.pageTitle = 'Add Task';
            this.fromTaskList = this.fromImedClaimService ? false : true;
            this.getImedClaimByServiceId();
        }
    }

    getImedClaimByServiceId(): void {
        this.imedClaimsService.getByImedClaimServiceId(this.imedClaimServiceId).subscribe((imedClaim) => {
            this.imedClaim = imedClaim;
            this.setUserAssignmentTypes();
        });
    }

    setConfig(): void {
        this.claimantName = this.imedClaim.FirstName + ' ' + this.imedClaim.LastName;
        if (!this.task) {
            this.task = Object.assign({}, emptyTask);
        }
        this.task.IsAutomated = false;
        this.controls = new TaskDynamicControlsPartial(this.task, null).Form;
        this.formFactory = new TaskDynamicConfig<ITask>(this.task, [], ['DueDate', 'TaskDescriptions', 'Notes', 'TaskAssignmentTypeId']);
    }

    userAssignmentTypeSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUserAssignmentTypes = event.selectedItems;
    }

    setUserAssignmentTypes(): void {
        this.userAssignmentTypeSubscription = this.taskService.getUserAssignmentTypes().subscribe((userAssignmentTypes) => {
            this.userAssignmentTypes = userAssignmentTypes;
            this.userAssignmentTypsSelectItems = userAssignmentTypes.map(
                (item) =>
                    new MultiselectItem(
                        new MetaItem(item.Id as number, `${item.Name}`),
                        this.task?.TaskUserAssignmentTypeAssociations.find((x) => x.TaskUserAssignmentTypeId === item.Id) !== undefined,
                    ),
            );

            this.selectedUserAssignmentTypes = this.task?.TaskUserAssignmentTypeAssociations.map((item) => {
                return this.userAssignmentTypes.find((x) => x.Id === item.TaskUserAssignmentTypeId);
            });

            this.setConfig();
        });
    }

    formSubmitted(): void {
        const form = this.taskForm;
        const task: ITask = form.value.Task;

        if (form.valid && this.selectedUserAssignmentTypes.length > 0) {
            if (this.task.Id > 0) {
                this.task.ModifiedById = this.authService.currentUser.getValue().Id;
            }

            this.formFactory.assignFormValues(this.task, task);
            this.task.ImedClaimId = this.imedClaim.Id;
            this.task.ImedClaim = this.imedClaim;
            this.task.AssignedToTypeId = AssignedToTypes.Users;
            this.task.ImedClaimServiceId = this.imedClaimServiceId;
            this.task.TaskUserAssignmentTypeAssociations = this.selectedUserAssignmentTypes.map((item) => {
                const taskUserAssignmentTypes: ITaskUserAssignmentTypeAssociation = {
                    Id: 0,
                    TaskId: this.task.Id,
                    TaskUserAssignmentTypeId: item.Id,
                };
                return taskUserAssignmentTypes;
            });

            if (this.id > 0) {
                this.taskService.updateTask(this.task).subscribe(
                    () => {
                        this.resetTaskForm();
                        void this.router.navigate(['../'], { relativeTo: this.route });
                        this.success();
                    },
                    (error) => this.notificationsService.error(error.error as string),
                );
            } else {
                this.taskService.createTask(this.task).subscribe(
                    () => {
                        this.resetTaskForm();
                        if (this.fromTaskList) {
                            void this.router.navigate(['../'], { relativeTo: this.route });
                        } else {
                            this.emitSave.emit(true);
                        }
                        this.success();
                    },
                    (error) => this.notificationsService.error(error.error as string),
                );
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    cancelClick(): void {
        if (this.fromTaskList || this.id > 0) {
            void this.router.navigate(['../'], { relativeTo: this.route });
        } else {
            this.emitCancel.emit(true);
        }
    }

    resetTaskForm(): void {
        this.taskForm.reset();
        const now = new Date();
        this.taskForm.patchValue({
            Task: {
                DueDate: now,
            },
        });
    }

    error(): void {
        this.notificationsService.error("Please make sure all required form fields (marked with '*') are filled out correctly.");
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    ngOnDestroy(): void {
        this.userAssignmentTypeSubscription.unsubscribe();
    }
}
