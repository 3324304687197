import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@mt-ng2/auth-module';
import { Observable } from 'rxjs';
import { TaskService } from '../../../tasks/task.service';

@Injectable({
    providedIn: 'root',
})
export class UserTaskGuardService {
    constructor(public auth: AuthService, public router: Router, public taskService: TaskService) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return new Observable<boolean>((obs) => {
            this.taskService.getById(+route.paramMap.get('taskId')).subscribe((task) => {
                if (task.CreatedById !== this.auth.currentUser.getValue().Id || task.IsAutomated) {
                    void this.router.navigate([`home`]);
                    obs.next(false);
                }
                obs.next(true);
            });
        });
    }
}
