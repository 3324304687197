import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IPhysician } from '@model/interfaces/physician';

@Injectable({ providedIn: 'root' })
export class PhysicianService extends BaseService<IPhysician> {
    constructor(public http: HttpClient) {
        super('/physician-expert', http);
    }

    getByUserId(userId: number): Observable<IPhysician> {
        return this.http.get<IPhysician>(`/physician-expert/getByUserId/${userId}`);
    }

    getMissingDocuments(userId: number): Observable<string[]> {
        return this.http.get<string[]>(`/physician-expert/getMissingDocumentsByUserId/${userId}`);
    }
}
