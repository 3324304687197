import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IAddress } from '@model/interfaces/address';
import { IExamLocations } from './../model/interfaces/custom/exam-locations.dto';

export const emptyAddress: IAddress = {
    Address1: null,
    Address2: null,
    Archived: false,
    City: null,
    CreatedById: 0,
    DateCreated: new Date(),
    Id: 0,
    ModifiedById: null,
    StateId: 0,
    Zip: null,
};


@Injectable()
export class AddressService extends BaseService<IAddress> {

    constructor(public http: HttpClient) {
        super('/addresses', http);
    }

    getEmptyAddress(): IAddress {
        return { ...emptyAddress };
    }

    getExamLocations(userId: number): Observable<HttpResponse<IAddress[]>> {
        return this.http.get<IAddress[]>(`/physician-expert/addresses/${userId}/exam-locations`, { observe: 'response' });
    }

    createTask(examLocationsDTO: IExamLocations, userId: number): Observable<HttpResponse<number>> {
        return this.http.post<number>(`/physician-expert/addresses/${userId}/create-task`, examLocationsDTO, { observe: 'response' });
    }
}
