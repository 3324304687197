import { ErrorHandler, NgModule, Injector } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler, ErrorsModuleConfigToken } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';
import { AppRoutingModule } from './app-routing.module';
import { AppNavModule } from './nav/app-nav.module';
import { SharedModule } from './common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { UserModule } from './users/user.module';
import { LoginConfigOverride } from './common/configs/login.config';
import { ToastrModule } from 'ngx-toastr';
import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { LandingPageModule } from './landing-page/landing-page.module';
import { ErrorConfigOverride } from './common/configs/error.config';
import { RequestAccessComponent } from './common/request-access/request-access.component';
import { RequestAccessService } from './common/request-access/request-access.service';
import { TimeZoneInterceptor } from '../app/time-zone.interceptor';
import { AuthConfigOverride } from './common/configs/auth.config';
import { setGlobalInjector } from './global-injector';
import { AppRoutingComponent } from './app-routing.component';
import { UserInactivityMonitorService } from '@app-shared/services/user-inactivity-monitor.service';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { CookieModule } from '@mt-ng2/cookie';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { PhysicianExpertPortalLoginComponent } from './login/physician-expert-portal-login.component';
import { PhysicianExpertPortalForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PhysicianExpertAccessComponent } from './physician-expert-portal-access/PhysicianExpertAccessComponent';
import { ImedClaimServiceModule } from './imed-claims/imed-claim-services/imed-claim-service.module';
import { ServiceService } from '@app-shared/services/service.service';
import { PhysicianModule } from './physicians/physician.module';
import { BannerComponent } from './banner/banner';
import { ExamLocationsComponent } from './exam-locations/exam-location-list/exam-locations.component';
import { AddressService } from './exam-locations/address.service';
import { AddressDeleteDynamicCellComponent } from './exam-locations/exam-location-list/exam-location-delete-dynamic-cell.component';
import { StateService } from '@app-shared/services/state.service';
import { CountyService } from '@app-shared/services/county.service';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        NotFoundComponent,
        PhysicianExpertAccessComponent,
        RequestAccessComponent,
        PhysicianExpertPortalLoginComponent,
        PhysicianExpertPortalForgotPasswordComponent,
        AppRoutingComponent,
        BannerComponent,
        ExamLocationsComponent,
        AddressDeleteDynamicCellComponent,
    ],
    imports: [
        BrowserModule,
        NgbModule,
        SharedModule.forRoot(),
        ImedClaimServiceModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.withOptions({ secure: true, sameSite: 'strict' }),
        EnvironmentModule,
        AuthModule.forRoot(AuthConfigOverride),
        LandingPageModule.forRoot(),
        UserModule.forRoot(),
        PhysicianModule.forRoot(),
        AppRoutingModule,
        NotificationsModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
        MtDisableDuringHttpCallsModule.forRoot(),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        RequestAccessService,
        UserInactivityMonitorService,
        ServiceService,
        AddressService,
        StateService,
        CountyService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TimeZoneInterceptor, multi: true, deps: [HttpBackend] },
        { provide: ErrorsModuleConfigToken, useValue: ErrorConfigOverride },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        setGlobalInjector(injector);
    }
}
