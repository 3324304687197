import { MasterServiceStatuses } from '../../common/Enums/master-service-status.enum';

export const StatusMapping = new Map<number, string>([
    [MasterServiceStatuses.Addendum_related_service, 'Addendum related services'],
    [MasterServiceStatuses.Additional_records_received_pending_compilations, 'Pending additional records'],
    [MasterServiceStatuses.Awaiting_report, 'Awaiting report'],
    [MasterServiceStatuses.Awaiting_report_revisions, 'Awaiting updated report'],
    [MasterServiceStatuses.Awaiting_report_pending_payment, 'Awaiting report'],
    [MasterServiceStatuses.Bill_paid_pending_check_clearance, 'Bill paid, pending check clearance'],
    [MasterServiceStatuses.Bill_processed_pending_payment, 'Bill processed, pending payment'],
    [MasterServiceStatuses.Bill_received_pending_payment, 'Bill received, pending payment'],
    [MasterServiceStatuses.Cancelled, 'Cancelled'],
    [MasterServiceStatuses.Car_Service_confirmed, 'Scheduled'],
    [MasterServiceStatuses.Case_Settled_Closed_without_service, 'Case settled'],
    [MasterServiceStatuses.Charges_paid_pending_check_clearance, 'Charges paid, pending check clearance'],
    [MasterServiceStatuses.Check_cleared, 'Check cleared'],
    [MasterServiceStatuses.Check_cleared_awaiting_report, 'Check cleared, awaiting report'],
    [MasterServiceStatuses.Check_cleared_pending_report, 'Check cleared, awaiting report'],
    [MasterServiceStatuses.Closed_for_incoming_Radiology, 'Scheduled'],
    [MasterServiceStatuses.Closed_without_service, 'Closed without services'],
    [MasterServiceStatuses.Complete, 'Complete'],
    [MasterServiceStatuses.Complete_testimony_cancelled, 'Cancelled'],
    [MasterServiceStatuses.Complete_testimony_completed, 'Complete'],
    [MasterServiceStatuses.Complete_to_be_invoiced, 'Complete'],
    [MasterServiceStatuses.Complete_to_be_invoiced_report_not_requested, 'Complete, report not requested'],
    [MasterServiceStatuses.Conference_call_confirmed, 'Conference call scheduled'],
    [MasterServiceStatuses.Conference_call_scheduled, 'Conference call scheduled'],
    [MasterServiceStatuses.Dates_confirmed_pending_payment, 'Scheduled'],
    [MasterServiceStatuses.Dates_requested_pending_confirmation, 'Pending scheduling'],
    [MasterServiceStatuses.Draft_report_forwarded_to_carrier_adjuster, 'Pending client update'],
    [MasterServiceStatuses.Draft_report_forwarded_to_defense_counsel, 'Pending client update'],
    [MasterServiceStatuses.Duplicate__closed, 'Duplicate - closed'],
    [MasterServiceStatuses.Facility_requires_subpoena_pending_receipt_from_DC, '-'],
    [MasterServiceStatuses.Films_received_pending_distribution_to_expert, 'Pending film distribution'],
    [MasterServiceStatuses.Films_received_pending_distribution_to_service, 'Pending film distribution'],
    [MasterServiceStatuses.IME_expert_consultation_related_service, 'IME expert consultation related service'],
    [MasterServiceStatuses.New_service_built_by_client_needs_review, 'Pending scheduling'],
    [MasterServiceStatuses.No_show_manual, 'No show, pending reschedule'],
    [MasterServiceStatuses.No_show_multiple_do_not_reschedule, 'No show, not rescheduling'],
    [MasterServiceStatuses.No_show_pending_advice_from_client, 'No show'],
    [MasterServiceStatuses.No_show_pending_reschedule, 'Now show, pending reschedule'],
    [MasterServiceStatuses.Pending_additional_records_report_will_be_held_pending_receipt, 'Pending additional records, hold report'],
    [MasterServiceStatuses.Pending_confirmation_of_notice_received, 'Scheduled'],
    [MasterServiceStatuses.Pending_consent_to_chnage_attorney_paperwork, 'Pending information from requestor'],
    [MasterServiceStatuses.Pending_prepayment, 'Scheduled'],
    [MasterServiceStatuses.Pending_receipt_of_films_from_client, 'Scheduled'],
    [MasterServiceStatuses.Pending_receipt_of_films_from_facility__facilities, 'Scheduled'],
    [MasterServiceStatuses.Pending_recruitment, 'Scheduled'],
    [MasterServiceStatuses.Pending_rescheduling, 'Pending rescheduling'],
    [MasterServiceStatuses.Pending_scheduling, 'Pending scheduling'],
    [MasterServiceStatuses.Pending_scheduling_review, 'Pending review by Imedview team'],
    [MasterServiceStatuses.Physician_selected_waiting_for_authorization_to_move_forward, 'Pending scheduling'],
    [MasterServiceStatuses.Prepayment_required, 'Invoice needed'],
    [MasterServiceStatuses.Record_review_releated_service, 'Record review related service'],
    [MasterServiceStatuses.Report_received_from_physician_pending_audit, 'Report received, pending review by Imedview team'],
    [MasterServiceStatuses.Requested_pending_physician_selection, 'Pending scheduling'],
    [MasterServiceStatuses.Rescheduled_pending_confirmation_of_appointment, 'Rescheduled'],
    [MasterServiceStatuses.Rescheduled_pending_confirmation_of_attendance, 'Attendance confirmation needed'],
    [MasterServiceStatuses.Rescheduled_pending_confirmation_of_notification_received, 'Rescheduled'],
    [MasterServiceStatuses.Rescheduled_pending_receipt_of_records, 'Rescheduled'],
    [MasterServiceStatuses.Rescheduled_send_notification_of_appointment, 'Rescheduled'],
    [MasterServiceStatuses.Scheduled__send_letter__notice, 'Scheduled'],
    [MasterServiceStatuses.Scheduled_confirmation_forwarded_to_physician_and_defense_counsel, 'Scheduled'],
    [MasterServiceStatuses.Scheduled_pending_compilation_of_records, 'Scheduled'],
    [MasterServiceStatuses.Scheduled_pending_confirmation_of_appointment, 'Scheduled'],
    [MasterServiceStatuses.Scheduled_pending_confirmation_of_attendance, 'Attendance confirmation needed'],
    [MasterServiceStatuses.Scheduled_pending_confirmation_of_medical_records_received, 'Please confirm medical records received'],
    [MasterServiceStatuses.Scheduled_pending_receipt_of_records, 'Scheduled'],
    [MasterServiceStatuses.Scheduled_pending_review_of_records, 'Scheduled'],
    [MasterServiceStatuses.Service_cancelled_by_carrier_adjuster, 'Cancelled'],
    [MasterServiceStatuses.Service_cancelled_by_defense_counsel, 'Cancelled'],
    [MasterServiceStatuses.Service_complete_report_not_requested, 'Complete, report not requested'],
]);

