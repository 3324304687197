import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IAddress } from '@model/interfaces/address';
import { AddressDeleteDynamicCellComponent } from './exam-location-delete-dynamic-cell.component';

export class AddressesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Address1',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    name: 'Address2',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    name: 'City',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (address: IAddress) => (address.County && address.County.CountyName ? address.County.CountyName : ''),
                    name: 'County',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['State', 'StateCode'],
                    name: 'State',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    name: 'Zip',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    component: AddressDeleteDynamicCellComponent,
                    name: 'Delete',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: IAddress) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
