export const appPaths = {
    adminAccess: 'adminaccess',
    physicianExpertAccess: 'clientaccess',
    forgotPassword: 'forgotpassword',
    home: 'home',
    login: 'login',
    myProfile: 'my-profile',
    requestAccess: 'requestaccess',
    resetPassword: 'resetpassword',
    serviceRequest: 'service-request',
    settings: 'settings',
    verify2FA: 'verify',
    services: 'services',
    physicinExpertDocumentUpload: 'upload-documents',
    managePhysicianExamLocations: 'manage-exam-locations',
};
