import { NgModule } from '@angular/core';
import { SharedModule } from '../../common/shared.module';
import { ImedClaimSubServiceService } from '@app-shared/services/imed-claim-sub-service.service';
import { ImedClaimServiceListComponent } from './imed-claim-service-list/imed-claim-services.component';
import { ImedClaimServiceRoutingModule } from '../imed-claim-routing.module';
import { ImedClaimBasicInfoComponent } from '../../imed-claims/imed-claim-basic-info/imed-claim-basic-info.component';
import { ImedClaimServiceBasicInfoComponent } from './imed-claim-service-basic-info/imed-claim-service-basic-info.component';
import { ImedClaimServiceDetailComponent } from './imed-claim-service-detail/imed-claim-service-detail.component';
import { ImedClaimServiceAttachmentsComponent } from './attachment/imed-claim-attachments.component';
import { TaskBasicInfoComponent } from '../../tasks/task-basic-info/task-basic-info.component';
import { TaskDetailComponent } from '../../tasks/task-detail/task-detail.component';
import { TaskListComponent } from '../../tasks/task-list/task-list.component';

@NgModule({
    declarations: [
        ImedClaimServiceListComponent,
        ImedClaimBasicInfoComponent,
        ImedClaimServiceBasicInfoComponent,
        ImedClaimServiceDetailComponent,
        ImedClaimServiceAttachmentsComponent,
        TaskBasicInfoComponent,
        TaskDetailComponent,
        TaskListComponent,
    ],
    imports: [SharedModule, ImedClaimServiceRoutingModule],
})
export class ImedClaimServiceModule {
    static forRoot(): any {
        return {
            ngModule: ImedClaimServiceModule,
            providers: [ImedClaimSubServiceService],
        };
    }
}
