import { Component } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IAddress } from '@model/interfaces/address';

@Component({
    template: `
        <div *ngIf="address">
            <div *ngIf="!address.Archived" class="text-left">
                <i (click)="onClick($event)" class="fa fa-trash fa-lg" aria-hidden="true"></i>
            </div>
            <div *ngIf="address.Archived" class="text-left">
                <span (click)="onClick($event)" class="fa fa-trash fa-lg" aria-hidden="true"><i class="fa fa-lg fa-undo overlay-fa"></i></span>
            </div>
        </div>
    `,
})
export class AddressDeleteDynamicCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.address = value as IAddress;
    }
    address: IAddress;

    constructor() {}

    onClick(event: Event): void {
        this.entityListComponentMembers.itemDeleted(this.address as IEntity, event);
    }
}
