import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { BaseService } from '@mt-ng2/base-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ImedClaimService extends BaseService<IImedClaim> {
    private _isDuplicate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    getIsDuplicate(): Observable<boolean> {
        return this._isDuplicate.asObservable();
    }
    setIsDuplicate(val: boolean): void {
        this._isDuplicate.next(val);
    }

    constructor(public http: HttpClient) {
        super('/physician-expert/imed-claims', http);
    }

    getByImedClaimServiceId(imedClaimServiceId: number): Observable<IImedClaim> {
        return this.http.get<IImedClaim>(`/physician-expert/imed-claims/get-by-imed-claim-service-id/${imedClaimServiceId}`);
    }
}
