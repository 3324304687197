
import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarRowItem({
        content: 'Services',
        icon: 'fa fa-fw fa-user-md',
        link: '/services',
    }),
    new NavSidebarRowItem({
        content: 'Upload Documents',
        icon: 'fa fa-fw fa-upload',
        link: '/upload-documents',
    }),
    new NavSidebarRowItem({
        content: 'Manage Exam Locations',
        icon: 'fa fa-fw fa-map-marker',
        link: '/manage-exam-locations',
    }),
];
export const appCollapsedNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarRowItem({
        content: 'Services',
        icon: 'fa fa-fw fa-user-md',
        link: '/services',
    }),
    new NavSidebarRowItem({
        content: 'Upload Documents',
        icon: 'fa fa-fw fa-upload',
        link: '/upload-documents',
    }),
    new NavSidebarRowItem({
        content: 'Manage Exam Locations',
        icon: 'fa fa-fw fa-map-marker',
        link: '/manage-exam-locations',
    }),
];
// tslint:enable:object-literal-sort-keys
