import { DynamicField, DynamicFieldTypes, DynamicFieldType, InputTypes } from '@mt-ng2/dynamic-form';
import { TaskDynamicControls, ITaskDynamicControlsParameters } from '@model/form-controls/task.form-controls';
import { ITask } from '@model/interfaces/task';

export class TaskDynamicControlsPartial extends TaskDynamicControls {
    constructor(task?: ITask, additionalParameters?: ITaskDynamicControlsParameters) {
        super(task, additionalParameters);

        (<DynamicField>this.Form.TaskDescriptions).setRequired(true);
        (<DynamicField>this.Form.ImedClaimId).setRequired(true);

        const now = new Date();
        const dyType = new DynamicFieldType({
            datepickerOptions: {
                minDate: {
                    day: now.getDate(),
                    month: now.getMonth() + 1,
                    year: now.getFullYear(),
                },
            },
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Datepicker,
        });
        this.Form.DueDate = new DynamicField({
            formGroup: this.formGroup,
            label: 'Due Date',
            name: 'DueDate',
            type: dyType,
            value: task?.DueDate ? task?.DueDate : now,
        });
        (<DynamicField>this.Form.DueDate).setRequired(true);
        (<DynamicField>this.Form.AssignedToTypeId).setRequired(true);
    }
}
