import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { AuthService } from '@mt-ng2/auth-module';
import { IAddress } from '@model/interfaces/address';
import { AddressesEntityListConfig } from './exam-locations.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AddressService, emptyAddress } from '../address.service';
import { finalize, forkJoin } from 'rxjs';
import { CountyService } from '@app-shared/services/county.service';
import { StateService } from '@app-shared/services/state.service';
import { AddressDynamicConfig } from '../address.dynamic-config';
import { IItemDeletedEvent } from '@mt-ng2/entity-list-module';
import { IState } from '@model/interfaces/state';
import { AddressDynamicControls } from '@model/form-controls/address.form-controls';
import { IExamLocations } from '../../model/interfaces/custom/exam-locations.dto';

@Component({
    selector: 'app-addresses',
    templateUrl: './exam-locations.component.html',
})
export class ExamLocationsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    examLocations: IAddress[];
    currentPage = 1;
    total: number;
    entityListConfig = new AddressesEntityListConfig();
    addressBookId: number;
    archivedExamLocations: IAddress[] = [];
    newExamLocations: IAddress[] = [];
    currentUserId: number;
    formFactory: AddressDynamicConfig<IAddress>;
    addressForm = new UntypedFormGroup({ Address: new UntypedFormGroup({}) });
    addressControls: any;
    address: IAddress;
    showNewExamLocationForm = false;
    states: IState[];
    countyName: string;
    newExamLocation: IAddress;
    formRendered = false;

    constructor(
        private addressService: AddressService,
        private notificationService: NotificationsService,
        private authService: AuthService,
        private stateService: StateService,
        private cdr: ChangeDetectorRef,
        private countyService: CountyService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.addressControls = <AddressDynamicControls>this.addressControls;

        forkJoin([this.stateService.getItems()]).subscribe((answer) => {
            this.states = answer[0];
        });
        this.getExamLocations();
    }

    getExamLocations(): void {
        this.addressService.getExamLocations(this.currentUserId).subscribe((answer) => {
            this.examLocations = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.formRendered = true;
        });
    }

    onExistingExamLocationDeleted(event: IItemDeletedEvent): void {
        const selectedAddress: IAddress = event.entity;
        selectedAddress.Archived = !selectedAddress.Archived;

        if (selectedAddress.Archived) {
            this.archivedExamLocations.push(selectedAddress);
        } else {
            this.archivedExamLocations = this.archivedExamLocations.filter((x) => x.Id !== selectedAddress.Id);
        }
    }

    onItemAdd(): void {
        if (this.addressForm.invalid) {
            this.notificationService.error('Please fill out all required fields.');
            return;
        }

        this.newExamLocation = this.addressForm.value.Address;
        this.newExamLocation.State = this.states.find((x) => x.Id === this.addressForm.value.Address.StateId);
        this.newExamLocation.County = { CountyName: this.countyName } as IAddress['County'];
        this.newExamLocations.push(this.newExamLocation);
        this.showNewExamLocationForm = false;
    }

    onSubmit(): void {
        const examLocationsDTO = this.createExamLocationsDTO();

        this.addressService
            .createTask(examLocationsDTO, this.currentUserId)
            .pipe(
                finalize(() => {
                    this.showNewExamLocationForm = false;
                }),
            )
            .subscribe(
                () => {
                    this.notificationService.success(
                        'A task has been submitted to Imedview to review your request for updated Exam Locations. Please allow time for processing.',
                    );
                    this.archivedExamLocations = [];
                    this.newExamLocations = [];
                    this.getExamLocations();
                },
                (error) => {
                    this.notificationsService.error(error.error as string);
                },
            );
    }

    createExamLocationsDTO(): IExamLocations {
        return {
            ArchivedExamLocations: this.archivedExamLocations,
            NewExamLocations: this.newExamLocations,
        };
    }

    createNewExamLocationForm(): void {
        if (!this.address) {
            this.address = Object.assign({}, emptyAddress);
        }

        this.addressControls = new AddressDynamicControls(this.address, {
            formGroup: 'Address',
            states: this.states,
        }).Form;

        this.formFactory = new AddressDynamicConfig<IAddress>(this.address, null);
        this.showNewExamLocationForm = true;
        this.cdr.detectChanges();
    }

    getCountyName(): void {
        this.countyService.getByZip(this.addressForm.value.Address.Zip as string).subscribe((answer) => {
            if (answer) {
                this.countyName = answer.CountyName;
            }
        });
    }

    onNewExamLocationDeleted(event: IItemDeletedEvent): void {
        const selectedAddress: IAddress = event.entity;
        selectedAddress.Archived = !selectedAddress.Archived;

        this.newExamLocations = this.newExamLocations.filter((x) => !x.Archived);
    }
}
