import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { IImedClaimDTO } from '../../model/interfaces/custom/service-detail-dto';

@Component({
    selector: 'app-imed-claim-basic-info',
    templateUrl: './imed-claim-basic-info.component.html',
})
export class ImedClaimBasicInfoComponent implements OnInit {
    @Input() imedClaim: IImedClaimDTO;

    constructor(private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.setBirthDate();
        this.setDateOfInjuryAccident();
    }

    setBirthDate(): void {
        if (this.imedClaim.Birthdate) {
            this.imedClaim.Birthdate = this.datePipe.transform(this.imedClaim.Birthdate, 'mediumDate', 'UTC');
        }
    }

    setDateOfInjuryAccident(): void {
        if (this.imedClaim.DateOfAllegedAccidentOrInjury) {
            this.imedClaim.DateOfAllegedAccidentOrInjury = this.datePipe.transform(this.imedClaim.DateOfAllegedAccidentOrInjury, 'mediumDate', 'UTC');
        }
    }
}
