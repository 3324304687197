export enum MasterServiceStatuses {
    Addendum_related_service = 66,
    Additional_records_received_pending_compilations = 88,
    Awaiting_report_revisions = 2,
    Awaiting_report = 1,
    Awaiting_report_pending_payment = 78,
    Bill_paid_pending_check_clearance = 3,
    Bill_processed_pending_payment = 4,
    Bill_received_pending_payment = 5,
    Cancelled = 6,
    Car_Service_confirmed = 89,
    Case_Settled_Closed_without_service = 67,
    Charges_paid_pending_check_clearance = 7,
    Check_cleared = 8,
    Check_cleared_awaiting_report = 9,
    Check_cleared_pending_report = 10,
    Closed_without_service = 11,
    Closed_for_incoming_Radiology = 99,
    Complete = 12,
    Complete_testimony_cancelled = 13,
    Complete_testimony_completed = 14,
    Complete_to_be_invoiced = 15,
    Complete_to_be_invoiced_report_not_requested = 80,
    Conference_call_confirmed = 92,
    Conference_call_scheduled = 16,
    Dates_confirmed_pending_payment = 17,
    Dates_requested_pending_confirmation = 18,
    Draft_report_forwarded_to_carrier_adjuster = 81,
    Draft_report_forwarded_to_defense_counsel = 68,
    Duplicate__closed = 20,
    Facility_requires_subpoena_pending_receipt_from_DC = 21,
    Films_received_pending_distribution_to_expert = 82,
    Films_received_pending_distribution_to_service = 90,
    IME_expert_consultation_related_service = 69,
    New_service_built_by_client_needs_review = 100,
    Films_forwarded_to_expert_pending_report = 22,
    Medical_records_received_pending_submission_to_client = 23,
    No_show_manual = 24,
    No_show_multiple_do_not_reschedule = 83,
    No_show_pending_advice_from_client = 84,
    No_show_pending_reschedule = 85,
    Payment_forwarded_to_be_invoiced = 25,
    Pending_additional_records_report_will_be_held_pending_receipt = 38,
    Pending_bill_from_facility = 26,
    Pending_confirmation_of_notice_received = 27,
    Pending_consent_to_chnage_attorney_paperwork = 101,
    Pending_prepayment = 28,
    Pending_receipt_of_films_from_client = 29,
    Pending_receipt_of_films_from_facility__facilities = 30,
    Pending_recruitment = 31,
    Pending_rescheduling = 32,
    Pending_retrieval_document_submission_by_mail_or_fax = 33,
    Pending_scheduling = 34,
    Pending_scheduling_review = 102,
    Physician_selected_waiting_for_authorization_to_move_forward = 35,
    Prepayment_required = 36,
    Record_review_releated_service = 79,
    Records_compiled_awaiting_report = 37,
    Report_received_from_physician_pending_audit = 19,
    Requested_pending_physician_selection = 76,
    Report_prepared_awaiting_review_and_revision = 39,
    Rescheduled_pending_confirmation_of_appointment = 40,
    Rescheduled_pending_confirmation_of_attendance = 41,
    Rescheduled_pending_confirmation_of_notification_received = 42,
    Rescheduled_pending_receipt_of_records = 77,
    Rescheduled_send_notification_of_appointment = 43,
    Scheduled__send_letter__notice = 44,
    Scheduled_confirmation_forwarded_to_physician_and_defense_counsel = 45,
    Scheduled_pending_compilation_of_records = 46,
    Scheduled_pending_confirmation_of_appointment = 47,
    Scheduled_pending_confirmation_of_attendance = 48,
    Scheduled_pending_confirmation_of_medical_records_received = 49,
    Scheduled_pending_receipt_of_records = 50,
    Scheduled_pending_review_of_records = 51,
    Service_cancelled_by_carrier_adjuster = 86,
    Service_cancelled_by_defense_counsel = 52,
    Service_complete_report_not_requested = 87,
    Suspended_until_further_notice = 53,
    Waiting_for_invoice_from_facility__doctor = 54,
    Waiting_for_medical_records__films = 55,
    Waiting_for_payment_authorization = 56,
    Waiting_for_revised_AZ = 57,
    Check_cleared_awaiting_report_revisions = 58,
    IME_related_radiology_review = 59,
    Pending_additional_records_report_will_be_finalized_and_addendum_requested = 60,
    Report_audit_in_progress = 61,
    Report_revisions_pending_payment = 62,
    Rescheduled_pending_compilation_of_records = 63,
    Rescheduled_pending_confirmation_of_medical_records_received__retained = 64,
}
