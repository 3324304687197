import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, Subscription, tap } from 'rxjs';
import { ImedClaimServiceService } from '../imed-claim-service.service';
import { ActivatedRoute } from '@angular/router';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { ModalService as CustomModalService, ModalTypes } from '../../../../app/common/services/modal.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IFilmInformation } from '@model/interfaces/film-information';
import { IServiceDetailDTO } from '../../../model/interfaces/custom/service-detail-dto';
import { IAttachmentDTO } from '../../../model/interfaces/custom/attachment-dto';
import { HttpResponse } from '@angular/common/http';
import { BaseAttachmentsService } from '../../../common/attachments/base-attachments.service';
import { ImedClaimServiceAttachmentsComponent } from '../attachment/imed-claim-attachments.component';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { TaskService } from '../../../tasks/task.service';
import { AuthService } from '@mt-ng2/auth-module';
import { StatusMapping } from '../imed-claim-service-status-mapping';
@Component({
    selector: 'app-imed-claim-service-detail',
    templateUrl: './imed-claim-service-detail.component.html',
})
export class ImedClaimServiceDetailComponent implements OnInit {
    @ViewChild('serviceDocuments') documentsComponent: ImedClaimServiceAttachmentsComponent;
    imedClaimServiceDeatil: IServiceDetailDTO;
    formRendered = false;
    imedClaimServiceId: number;
    canEdit = true;
    searchParameter: string;
    documentArray: IAttachmentDTO[] = [];
    documentSubscription: Subscription;
    showRadiologyReviewIcon = false;
    filmInfo: IFilmInformationDTO[] = [];
    addingTask = false;
    tasks: ITaskDTO[];
    currentUserId: number;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private route: ActivatedRoute,
        private attachmentsService: BaseAttachmentsService,
        private cdr: ChangeDetectorRef,
        private customModalService: CustomModalService,
        private notificationsService: NotificationsService,
        private taskService: TaskService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.imedClaimServiceId = +this.route.snapshot.paramMap.get('serviceId');
        forkJoin([this.imedClaimServiceService.getServiceDetail(this.imedClaimServiceId)]).subscribe(([imedClaimService]) => {
            this.imedClaimServiceDeatil = imedClaimService.body;
            this.MapMasterServiceStatusIdToPhysicianPortalViewableServiceStatus();
            this.getDocuments().subscribe();
            if (this.documentsComponent) {
                this.documentsComponent.isEditing = this.documentsComponent.Files.some((doc) => doc.FileItem.isError) ? true : false;
            }
            this.subscribeToDocumentSort();
            this.cdr.detectChanges();
            this.showRadiologyReviewIcon = this.imedClaimServiceDeatil.ImedClaimService.HasRadiologyRetrieval;
            this.formRendered = true;
            this.getImedClaimServiceTasks().subscribe();
        });
        this.currentUserId = this.authService.currentUser.getValue().Id;
    }

    refreshDocuments(name: string): void {
        if (this.imedClaimServiceId) {
            this.searchParameter = name;
            this.getDocuments().subscribe();
        }

        this.imedClaimServiceService.getServiceDetail(this.imedClaimServiceId).subscribe((response) => {
            this.imedClaimServiceDeatil = response.body;
            this.cdr.detectChanges();
        });
    }

    getDocuments(): Observable<HttpResponse<IAttachmentDTO[]>> {
        return this.attachmentsService
            .all('imed-claim-service', this.imedClaimServiceId, this.searchParameter)
            .pipe(tap((response) => (this.documentArray = response.body)));
    }

    subscribeToDocumentSort(): void {
        this.documentSubscription = this.attachmentsService.currentSort().subscribe(() => {
            this.getDocuments().subscribe();
        });
    }

    showFilmModal(modal): void {
        this.imedClaimServiceService
            .getFilmInformationsByImedClaimServiceId(
                this.imedClaimServiceDeatil.ImedClaimService.Id,
                this.imedClaimServiceDeatil.ImedClaimService.ServiceTypeId,
            )
            .subscribe((films) => {
                this.filmInfo = films;
                this.customModalService.showDocumentModal(modal, ModalTypes.FILM);
            });
    }

    delete(filmInformation: IFilmInformation): void {
        filmInformation.Archived = true;
        this.imedClaimServiceService.requestDelete(filmInformation.Id).subscribe(
            () => {
                this.notificationsService.success('Successfully requested the film to be deleted!');
            },
            () => {
                this.notificationsService.error('An error occurred while requesting the film to be deleted!');
            },
            () => {
                this.imedClaimServiceService.getFilmInformationsByImedClaimServiceId(this.imedClaimServiceDeatil.ImedClaimService.Id, this.imedClaimServiceDeatil.ImedClaimService.ServiceTypeId).subscribe((filmInfo) => {
                    setTimeout(() => (this.filmInfo = filmInfo));
                });
            },
        );
    }

    getImedClaimServiceTasks(): Observable<ITaskDTO[]> {
        const searchEntity: IEntitySearchParams = {
            order: 'DateCreated',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 100,
        };

        const csp = new SearchParams(searchEntity);
        return this.taskService.getTasksForService(this.imedClaimServiceDeatil.ImedClaimService.Id, csp).pipe(
            tap(({ body: tasks }) => {
                this.tasks = tasks;
            }),
        );
    }

    MapMasterServiceStatusIdToPhysicianPortalViewableServiceStatus(): void {
        const masterStatusId = this.imedClaimServiceDeatil.ImedClaimService.MasterServiceStatusId;
        const statusName = StatusMapping.get(masterStatusId);

        if (statusName) {
            this.imedClaimServiceDeatil.ImedClaimService.ServiceStatus = statusName;
        }
    }
}
