import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, MtTwoFactorGuard } from '@mt-ng2/auth-module';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { ResetPasswordComponent, AdminAccessComponent, TwoFactorComponent } from '@mt-ng2/login-module';
import { appPaths } from './default-routes/app-paths.library';
import { PhysicianExpertAccessComponent } from './physician-expert-portal-access/PhysicianExpertAccessComponent';
import { PhysicianExpertPortalLoginComponent } from './login/physician-expert-portal-login.component';
import { PhysicianExpertPortalForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AppRoutingComponent } from './app-routing.component';
import { RequestAccessComponent } from '../physician-expert-portal/common/request-access/request-access.component';
import { ImedClaimServiceListComponent } from './imed-claims/imed-claim-services/imed-claim-service-list/imed-claim-services.component';
import { ExamLocationsComponent } from './exam-locations/exam-location-list/exam-locations.component';

const appRoutes: Routes = [
    { path: appPaths.login, component: PhysicianExpertPortalLoginComponent },
    { path: appPaths.forgotPassword, component: PhysicianExpertPortalForgotPasswordComponent },
    { path: appPaths.requestAccess, component: RequestAccessComponent },

    { path: appPaths.resetPassword, component: ResetPasswordComponent, title: 'Reset Password' },

    { path: appPaths.adminAccess, component: AdminAccessComponent, title: 'Admin Access' },
    { path: appPaths.physicianExpertAccess, component: PhysicianExpertAccessComponent },
    {
        canActivate: [AuthGuard],
        component: ImedClaimServiceListComponent,
        path: appPaths.home,
    },
    {
        canActivate: [AuthGuard],
        component: ImedClaimServiceListComponent,
        path: appPaths.services,
    },
    {
        canActivate: [AuthGuard],
        component: UserDetailComponent,
        path: appPaths.myProfile,
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: ExamLocationsComponent,
        path: appPaths.managePhysicianExamLocations,
    },
    {
        canActivate: [MtTwoFactorGuard],
        component: TwoFactorComponent,
        path: appPaths.verify2FA,
    },
    {
        canActivate: [AuthGuard],
        component: AppRoutingComponent,
        path: '**',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload'})],
})
export class AppRoutingModule {}
