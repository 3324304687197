import { IAuthConfig, AuthService } from '@mt-ng2/auth-module';
import { LogoutLogService } from '../../../app-shared/services/logout-log.service';
import { LoginLogService } from '../../../app-shared/services/login-log.service';
import { Observable } from 'rxjs';
import { globalInjector } from './../../../physician-expert-portal/global-injector';

export const AuthConfigOverride: IAuthConfig = {
    eventHandlers: {
        onBeforeLogout: OnBeforeLogoutHandler,
        onLogin: LoginHandler,
    },
    pathOverrides: {
        myProfilePath: null,
    },
};

export function OnBeforeLogoutHandler(): Observable<void> {
    const logoutService = globalInjector.get(LogoutLogService);
    return logoutService.logLogout();
}

export function LoginHandler(): void {
    const loginService = globalInjector.get(LoginLogService);
    const authService = globalInjector.get(AuthService);
    loginService.logLogin(authService.currentUser.getValue().Id).subscribe();
}
