import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ImedClaimServiceDetailComponent } from './imed-claim-services/imed-claim-service-detail/imed-claim-service-detail.component';
import { ImedClaimServiceService } from './imed-claim-services/imed-claim-service.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { ImedClaimServiceListComponent } from './imed-claim-services/imed-claim-service-list/imed-claim-services.component';
import { TaskListComponent } from '../tasks/task-list/task-list.component';
import { TaskBasicInfoComponent } from '../tasks/task-basic-info/task-basic-info.component';
import { TaskService } from '../tasks/task.service';
import { UserTaskGuardService } from './services/tasks/user-task-guard.service';


const imedClaimServiceEntityConfig = {
    claimType: ClaimTypes.PhysicianExpertPortalServiceManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'serviceId',
    service: ImedClaimServiceService,
    title: 'Service Detail',
};

const taskEntityConfig = {
    claimType: ClaimTypes.PhysicianExpertPortalServiceManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'taskId',
    service: TaskService,
    title: 'Task Detail',
};

const imedClaimTaskListRoleGuard = {
    claimType: ClaimTypes.PhysicianExpertPortalServiceManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Tasks',
};

const imedClaimServiceRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ImedClaimServiceListComponent,
        path: 'services',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: ImedClaimServiceDetailComponent,
        path: `service/:${imedClaimServiceEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: ImedClaimServiceDetailComponent,
                path: '',
                pathMatch: 'full',
            },
            {
                children: [
                    {
                        component: TaskListComponent,
                        path: '',
                    },
                    {
                        component: TaskBasicInfoComponent,
                        path: 'add',
                    },
                    {
                        canActivate: [UserTaskGuardService],
                        component: TaskBasicInfoComponent,
                        data: taskEntityConfig,
                        path: `:${taskEntityConfig.entityIdParam}`,
                    },
                ],
                data: imedClaimTaskListRoleGuard,
                path: 'tasks',
            },
        ],
        path: `service/:${imedClaimServiceEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(imedClaimServiceRoutes)],
})
export class ImedClaimServiceRoutingModule {}
