import { NgModule } from '@angular/core';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { SharedModule } from '../common/shared.module';
import { PhysicianService } from './physicians.service';
import { PhysicianRoutingModule } from './physician-routing.module';
@NgModule({
    declarations: [UploadDocumentsComponent],
    imports: [SharedModule, PhysicianRoutingModule],
})
export class PhysicianModule {
    static forRoot(): any {
        return {
            ngModule: PhysicianModule,
            providers: [PhysicianService],
        };
    }
}
