import { NgModule } from '@angular/core';
import { NavModule, NavSidebarServiceToken, NavModuleConfigToken } from '@mt-ng2/nav-module';
import { NavConfigOverride } from '../common/configs/nav.config';
import { NavSidebarService } from './sidebar.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [],
    exports: [NavModule],
    imports: [NavModule, CommonModule, RouterModule, NgbModule]
})
export class AppNavModule {
    static forRoot(): any {
        return {
            ngModule: AppNavModule,
            providers: [
                { provide: NavSidebarServiceToken, useClass: NavSidebarService },
                { provide: NavModuleConfigToken, useValue: NavConfigOverride },
            ],
        };
    }
}
